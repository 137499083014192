import React from 'react'
import styled from 'styled-components'
import BuyMeACoffee from 'components/shared/BuyMeACoffee'

const Container = styled.footer`
  max-width: 1232px;
  text-align: center;
  border-top: solid 1px #ccc;
  padding: 20px 10px;
  margin: 0 auto;
  margin-top: 40px;
`
const SnsList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 0 auto 20px;
  font-size: 1.8rem;
`
const Copyright = styled.p`
  font-size: 1.3rem;
  color: #999;
  span {
    margin: 0 4px;
  }
  a {
    font-family: 'Montserrat';
    letter-spacing: -1px;
    color: #454545;
    &:hover {
      color: #28a745;
    }
  }
`
const SnsItem = ({href, klassName}) => {
  return (
    <li>
      <a href={href} target="_blank" rel="noreferrer noopener">
        <span className={klassName}/>
      </a>
    </li>
  )
}
const Footer = () => {

  const items = [
    { href: 'https://twitter.com/DaiAoki30', klassName: 'fa-twitter' },
    { href: 'https://github.com/DaiAoki', klassName: 'fa-github' },
    { href: 'https://www.facebook.com/dai.aoki.94', klassName: 'fa-facebook' },
  ].map((item, key) => <SnsItem key={key} href={item.href} klassName={item.klassName}/>)

  return (
    <Container className="footer">
      <SnsList>
        { items }
        <li>
          <BuyMeACoffee/>
        </li>
      </SnsList>
      <Copyright>
        © 2019 CheatSheet
        <span className="x-small">presented by</span>
        <a href="https://daiaoki.me/">DaiAoki</a>
      </Copyright>
    </Container>
  )
}
export default Footer

import React from 'react'

const BuyMeACoffee = React.memo((props) => (
  <a href="https://www.buymeacoffee.com/daiaoki" className="bmc-button" target="_blank" rel="noopener noreferrer">
    <img src="https://bmc-cdn.nyc3.digitaloceanspaces.com/BMC-button-images/BMC-btn-logo.svg" alt="Buy me a coffee"/>
    <span style={{marginLeft: '5px'}}>Buy me a coffee</span>
  </a>
))

export default BuyMeACoffee

const Dict = {
  ja: {
    subtitle_first: 'チートシートとはカンニングペーパーのことです。それが転じて、本来覚えることをまとめておいたものです。',
    subtitle_second: '要点をすぐに参照できるようにまとめてみました。',
  },
  en: {
    subtitle_first: 'Cheatsheet is useful weapon that summarize what we should remember.',
    subtitle_second: 'We summarize various topic into cheatsheet to be able to refer instantly.',
  },
}
export default Dict

import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/reset.scss'
import 'assets/css/base.scss'
import 'assets/css/fonts.scss'
import 'assets/css/utility.scss'
import 'assets/css/vs.scss'
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();

import React from 'react'
import {withRouter} from 'react-router-dom'

const routesGetter = ({ children: Children }) => {
  return withRouter(routesProps => {
    return (
      <Children {...routesProps} />
    )
  })
}

export default routesGetter

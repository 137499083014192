/* eslint-disable import/first */

import 'babel-polyfill'
import 'core-js'
import React, { Component, Suspense, lazy } from 'react'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from 'store/configureStore'
import TrackPageView from './utils/TrackPageView'
import I18nContext from 'contexts/I18n'
import MetaTag from 'components/shared/MetaTag'
import Layout from 'components/Layout'
const Root = lazy(() => import(/* webpackPrefetch: true */ 'components/Root'))
const Page = lazy(() => import(/* webpackPrefetch: true */ 'components/Page'))
import ScrollToTop from 'components/shared/article/ScrollToTop'

const store = configureStore()

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: 'ja',
    }
  }

  componentWillMount() {
    this.setI18nLocation()
  }

  setI18nLocation() {
    let domains = window.location.hostname.split('.')
    const subDomain = domains[0]

    switch(subDomain) {
    case 'en':
      this.setState({locale: 'en'})
      break
    default:
      this.setState({locale: 'ja'})
    }
  }

  render() {
    return (
      <Provider store={store}>
        <I18nContext.Provider value={this.state}>
          <ConnectedRouter history={history}>
            <TrackPageView>
              <ScrollToTop>
                <Layout>
                  <MetaTag locale={this.state.locale}/>
                  <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                      <Route exact path="/" component={Root}/>
                      <Route exact path="/:title([\w|-]+)" component={Page}/>
                      <Route component={Root}/>
                    </Switch>
                  </Suspense>
                </Layout>
              </ScrollToTop>
            </TrackPageView>
          </ConnectedRouter>
        </I18nContext.Provider>
      </Provider>
    )
  }
}

export default App

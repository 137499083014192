import React, {Component} from 'react'
import ReactGA from 'react-ga'
import routesGetter from './ga/Routes'

const Analitics = props => {
  ReactGA.pageview(
    props.location.pathname + props.location.search + props.location.hash
  )
  return null
}

const Routes = routesGetter({ children: Analitics })

class TrackPageView extends Component {
  constructor(props) {
    super(props)
    ReactGA.initialize('UA-142196348-1', {
      debug: process.env.NODE_ENV !== 'production',
    })
  }

  render() {
    return (
      <React.Fragment>
        <Routes />
        { this.props.children }
      </React.Fragment>
    )
  }
}

export default TrackPageView

const Dict = {
  ja: {
    title: 'エンジニアのためのWebチートシート',
    description: 'チートシートのまとめサイトです。プログラマー・エンジニアを対象に要点をすぐに参照できるようにチートシートにまとめてみました。Web開発、モバイルアプリ、CLIなど内容は多岐に渡ります。印刷してお手元に置いたり、ブックマークしてすぐに見られるようにしておくと便利です。',
    supplement: '補足',
    toc: 'このチートシートの目次',
  },
  en: {
    title: 'Web CheatSheet for Engineer',
    description: 'CheatSheet for programmer and engineer. We summarize knowledge into CheatSheets to be able to refer it speedy. There are various theme, for example, web-development, mobile-app and CLI etc. Please print it and put on your desk, or bookmark me.',
    supplement: 'Supplement',
    toc: 'Table Of Contents',
  },
}
export default Dict

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import I18nContext from 'contexts/I18n'
import sheet_with_pencil from 'assets/images/sheet_with_pencil.svg'
import Dict from 'config/i18n/header.js'
import { media } from 'utils/media.js'
import ja from 'assets/images/ja.png'
import en from 'assets/images/en.png'

const Container = styled.header`
  max-width: 1232px;
  margin: 0 auto;
  text-align: center;
  font-size: 2.0rem;
  letter-spacing: -0.1px;
  padding: 20px 0;
  margin-bottom: 40px;
  position: relative;
`
const ReverseLink = styled(Link)`
  content: '';
  display: block;
  position: absolute;
  top: 26px;
  left: 10px;
  width: 12px;
  height: 12px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: #666;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 0px;
    width: 85%;
    height: 85%;
    transform: rotate(-45deg);
    border-top: 2px solid #666;
    border-left: 2px solid #666;
  }
  &:hover:before {
    background-color: #0366d6;
  }
  &:hover:after {
    border-top: 2px solid #0366d6;
    border-left: 2px solid #0366d6;
  }
`
const SiteLogo = styled(Link)`
  display: inline-block;
  font-family: 'Montserrat';
  color: #000;
  margin-bottom: 20px;
  &:hover {
    color: #0366d6;
  }
  &:hover polygon {
    fill: #0366d6;
    transition-duration: 0.3s;
  }
  &:hover path {
    fill: #0366d6;
    transition-duration: 0.3s;
  }
`
const SiteLogoIcon = styled(ReactSVG)`
  display: inline-block;
  fill: #000;
  width: 16px;
  vertical-align: baseline;
  margin-left: 7px;
`
const LocaleSwitch = styled.div`
  position: absolute;
  top: 26px;
  right: 10px;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
`
const Item = styled.a`
  transition-duration: 0.3s;
  &:hover {
    color: #0366d6;
    text-decoration: underline;
  }
  &:not(:first-child) {
    margin-left: 21px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -11px;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      border-left: solid 1px #333;
    }
    ${ media.sp`
      margin-left: 11px;
      &:before {
        left: -6px;
      }
    `}
  }
  ${props => props.active ? `
    color: #000;
  ` : `
    color: #999;
  `}
  img {
    margin-right: 2px;
  }
  span {
    ${ media.sp`
      display: none;
    `}
  }
`
const SubTitle = styled.div`
  font-size: 1.2rem;
  color: #666;
  padding: 0 10px;
`
const Header = () => {
  return (
    <I18nContext.Consumer>
      {
        ({ locale }) => {
          return (
            <Container className="header">
              <ReverseLink to="/"/>
              <SiteLogo to="/">
                <span>CheatSheet</span>
                <SiteLogoIcon src={sheet_with_pencil} alt="site logo"/>
              </SiteLogo>
              <LocaleSwitch>
                <Item href={`https://web-cheatsheet.com${window.location.pathname}`} active={locale === 'ja'}>
                  <img src={ja} alt="ja icon"/>
                  <span>日本語</span>
                </Item>
                <Item href={`https://en.web-cheatsheet.com${window.location.pathname}`} active={locale === 'en'}>
                  <img src={en} alt="en icon"/>
                  <span>English</span>
                </Item>
              </LocaleSwitch>
              <SubTitle>
                { Dict[locale].subtitle_first }
                <br/>
                { Dict[locale].subtitle_second }
              </SubTitle>
            </Container>
          )
        }
      }
    </I18nContext.Consumer>
  )
}

export default Header

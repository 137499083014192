import React from 'react'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'

const Main = styled.main`
  max-width: 1232px;
  min-height: 60vh;
  padding: 0 10px;
  margin: 0 auto;
`
const Layout = ({ children }) => {
  return (
    <>
      <Header/>
      <Main className="main">{ children }</Main>
      <Footer/>
    </>
  )
}
export default Layout

import React from 'react'
import { withRouter } from 'react-router'
import page from 'config/pages'
import Dict from 'config/i18n/general'

class MetaTag extends React.Component {
  componentWillMount() {
    this.setMetaTag()
    this.props.history.listen((location, action) => {
      this.setMetaTag()
    })
  }

  setMetaTag() {
    const pathname = window.location.pathname.slice(1)

    const titleTag = document.querySelector('title')
    const metaDescription = document.querySelector('meta[name="description"]')
    const metaOgUrl = document.querySelector('meta[property="og:url"]')
    const metaOgTitle = document.querySelector('meta[property="og:title"]')
    const metaOgDescription = document.querySelector('meta[property="og:description"]')
    const metaTwitterTitle = document.querySelector('meta[name="twitter:title"]')
    const metaTwitterDescription = document.querySelector('meta[name="twitter:description"]')

    let title, description, target, category
    if(pathname === '') {
      title = Dict[this.props.locale].title
      description = Dict[this.props.locale].description
    }
    else {
      const categories = page[this.props.locale]
      Object.keys(categories).forEach(cate => {
        const pages = categories[cate]
        Object.keys(pages).forEach(page => {
          if(page === pathname) {
            category = cate
            target = pages[page]
          }
        })
      })
      title = `${target.formal} | ${Dict[this.props.locale].title}`
      description = target.description
    }
    titleTag.innerHTML = title
    metaDescription.content = description
    metaOgUrl.content = window.location.href
    metaOgTitle.content = title
    metaOgDescription.content = description
    metaTwitterTitle.content = title
    metaTwitterDescription.content = description

    /* JSON-LD setting */
    document.querySelectorAll('script[type="application/ld+json"]').forEach(sc => sc.parentNode.removeChild(sc))
    const jsonld = document.createElement("script")
    jsonld.setAttribute("type", "application/ld+json")
    const jsonldContent = pathname === "" ? {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "@id": this.props.locale === "ja" ? "https://web-cheatsheet.com/" : "https://en.web-cheatsheet.com/",
      "inLanguage": this.props.locale,
      "name": Dict[this.props.locale].title,
      "url": this.props.locale === "ja" ? "https://web-cheatsheet.com/" : "https://en.web-cheatsheet.com/",
      "author": {
        "@type": "Person",
        "@id": "https://daiaoki.me",
        "name": "DaiAoki",
        "url": "https://daiaoki.me",
        "image": "https://web-cheatsheet.com/author.jpg"
      },
      "image": "https://web-cheatsheet.com/cheatsheet_og.png",
      "description": Dict[this.props.locale].description
    } : {
      "@context": "https://schema.org/",
      "@type": "WebPage",
      "@id": this.props.locale === "ja" ? `https://web-cheatsheet.com/${pathname}` : `https://en.web-cheatsheet.com/${pathname}`,
      "inLanguage": this.props.locale,
      "name": title,
      "url": this.props.locale === "ja" ? `https://web-cheatsheet.com/${pathname}` : `https://en.web-cheatsheet.com/${pathname}`,
      "author": {
        "@type": "Person",
        "@id": "https://daiaoki.me",
        "name": "DaiAoki",
        "url": "https://daiaoki.me",
        "image": "https://web-cheatsheet.com/author.jpg"
      },
      "image": "https://web-cheatsheet.com/cheatsheet_og.png",
      "description": description
    }
    jsonld.innerHTML = JSON.stringify(jsonldContent)
    document.querySelector('head').appendChild(jsonld)

    const breadcrumb = document.createElement("script")
    breadcrumb.setAttribute("type", "application/ld+json")
    const breadcrumbContent = pathname === "" ? {
    } : {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "@id": this.props.locale === "ja" ? `https://web-cheatsheet.com/${pathname}` : `https://en.web-cheatsheet.com/${pathname}`,
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@id": this.props.locale === "ja" ? "https://web-cheatsheet.com/" : "https://en.web-cheatsheet.com/",
            "name": "チートシート一覧"
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@id": this.props.locale === "ja" ? "https://web-cheatsheet.com/" : "https://en.web-cheatsheet.com/",
            "name": category
          }
        },
        {
          "@type": "ListItem",
          "position": 3,
          "item": {
            "@id": this.props.locale === "ja" ? `https://web-cheatsheet.com/${pathname}` : `https://en.web-cheatsheet.com/${pathname}`,
            "name": title
          }
        }
      ]
    }
    breadcrumb.innerHTML = JSON.stringify(breadcrumbContent)
    document.querySelector('head').appendChild(breadcrumb)

    const software = document.createElement("script")
    software.setAttribute("type", "application/ld+json")
    const softwareContent = pathname === "" ? {
    } : {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": Dict[this.props.locale].title,
      "operatingSystem": "WEB",
      "applicationCategory": "ReferenceApplication",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5.0",
        "ratingCount": "100"
      },
      "offers": {
        "@type": "Offer",
        "price": "0",
        "priceCurrency": this.props.locale === "ja" ? "JPY" : "USD"
      }
    }
    software.innerHTML = JSON.stringify(softwareContent)
    document.querySelector('head').appendChild(software)
  }

  render() {
    return null
  }
}

export default withRouter(MetaTag)
